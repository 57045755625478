import React from "react"
import tw from 'twin.macro'
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Img from "gatsby-image"
import Section from "../components/section"
import Summary from "../components/summary"
import Footer from "../components/footer"
import Toast from "../components/toast"
import PlaceGrid from "../components/place/grid"
import CollectionCard from "../components/collection/card"
import PlaceItem from "../components/place/card"
import { Accent, H2, P } from "../components/typography"
import TagLink from "../components/place/tag-link"
import { searchSuggestions, pluralise } from "../utils/helpers"
require('@gouch/to-title-case')

const HolidayFilter = () => (
  <Toast
    to="/places?h=January%2026">
    Public Holiday: See what’s open Tuesday, January 26
    <span>→</span>
  </Toast>
)

const Featured = ({ collection, image, label }) => (
  <Link tw="relative no-underline grid grid-cols-6 grid-rows-6 lg:(grid grid-cols-10 grid-rows-1 height[66vh])" to={collection.fields.slug}>
    <div tw="absolute overflow-hidden inset-0 col-span-6 row-span-full lg:(col-span-6 relative)">
      <Img
        tw="transform scale-100 transition-transform duration-6000 hover:(scale-150)"
        style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
        imgStyle={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
        fluid={image.childImageSharp.fluid}
        alt={collection.frontmatter.title}
      />
    </div>
    <div tw="bg-thirdgray-50 text-thirdgray-900 row-start-4 row-end-6 col-start-3 col-end-7 p-6 z-50 lg:(col-span-4 pl-12 row-span-1 pr-12 self-center) dark:(bg-thirdgray-dark-50 text-thirdgray-dark-900)">
      <Accent>
        {label} — {collection.frontmatter.places.length} {pluralise(collection.frontmatter.places.length, 'Place')}
      </Accent>
      <h1 tw="text-2xl font-serif mt-4 md:(mt-5 text-4xl) lg:(mt-6 text-5xl)">{collection.frontmatter.title.toTitleCase()}</h1>
      <p tw="font-serif text-xl hidden lg:(block text-2xl) mt-4 md:(mt-5) lg:(mt-6)">{collection.frontmatter.summary}</p>
    </div>
  </Link>
)

const DirectoryWidget = () => {
  const ExampleFeatures = [ 'Good for Groups', 'Open Late', 'Takeaway', 'BYO Friendly']
  const ExampleOccasions = [ 'Coffee', 'Dining', 'Drink', 'Retail']
  return (
    <Section>
      <div tw="py-4 px-2 md:(py-8 flex justify-between items-center space-x-6)">
        <div tw="md:(flex-1)">
          <Accent>Directory</Accent>
          <H2 tw="my-4">Find Places Fast</H2>
          <P>Browse our ever-growing directory of Perth. Filter by feature and occasion, or try searching with a keyword, name, or suburb.</P>
        </div>
        <div tw="mt-4 md:(flex-1 mt-0)">
          <form
            action='/places'
            method='get'
            tw="rounded-md bg-thirdgray-200 relative flex items-center border-2 border-transparent border-solid focus-within:(bg-white border-blue-400) dark:(bg-thirdgray-dark-200 focus-within:(bg-black border-blue-700))">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" tw="ml-3 mr-1">
              <path fill="#7C8FB1" d="M5 0a5 5 0 014.172 7.757l1.246 1.245c.36-.02.725.09 1.02.328l.123.11 3.5 3.5a1.5 1.5 0 01-2.008 2.224l-.114-.103-3.5-3.5a1.496 1.496 0 01-.437-1.143L7.757 9.172A5 5 0 115 0zm0 2a3 3 0 100 6 3 3 0 000-6z" />
            </svg>
            <input
              type="text"
              placeholder={`Try ‘${searchSuggestions[Math.floor(Math.random() * searchSuggestions.length)]}’`}
              name='s'
              autocomplete='off'
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
              tw="relative z-10 appearance-none bg-transparent py-1 border-0 text-thirdgray-500 text-base flex-1 focus:(outline-none) dark:(text-thirdgray-dark-500)"
            />
            <div tw="absolute top-1 right-1 bottom-1 z-20">
              <button tw="appearance-none px-3 h-full block text-xs font-bold rounded bg-thirdgray-500 transition text-white hover:(bg-blue-600) focus:(bg-blue-600)" type="submit">Go →</button>
            </div>
          </form>
          <div tw="md:(flex items-center) mt-4">
            <div tw="md:(w-1/4 pr-2)">
              <Accent>Features</Accent>
            </div>
            <div tw="md:(w-3/4 pl-2)">
              {ExampleFeatures.map(tag => ( <TagLink key={tag} tag={tag}/> ))}
              <Link to="/places" tw="inline-block bg-blue-100 text-blue-600 text-sm px-2 py-1 rounded my-0.5 mr-1 no-underline dark:(bg-blue-900 text-blue-100)">More&hellip;</Link>
            </div>
          </div>
          <div tw="md:(flex items-center) mt-2">
            <div tw="md:(w-1/4 pr-2)">
              <Accent>Occasions</Accent>
            </div>
            <div tw="md:(w-3/4 pl-2)">
              {ExampleOccasions.map(occasion => (<Link key={occasion} to={`/places?o=${encodeURIComponent(occasion)}`} tw="inline-block bg-blue-100 text-blue-600 text-sm px-2 py-1 rounded my-0.5 mr-1 no-underline dark:(bg-blue-900 text-blue-100)">{occasion}</Link>))}
              <Link to="/places" tw="inline-block bg-blue-100 text-blue-600 text-sm px-2 py-1 rounded my-0.5 mr-1 no-underline dark:(bg-blue-900 text-blue-100)">All</Link>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

const HomePage = ({ data }) => {
  const showHolidayFilters = data.site.siteMetadata.holidayFilters
  return (
    <Layout styleContext="feature">
      <Seo title="Latest articles"/>
      {showHolidayFilters && <div tw="hidden lg:block"><HolidayFilter/></div>}
      <Featured label="Featured" collection={data.featuredCollection} image={data.featuredImage} />
      {showHolidayFilters && <div tw="lg:hidden"><HolidayFilter/></div>}

      <div tw="mt-1 md:mt-2">
        <div tw="grid grid-cols-2 grid-template-rows[33vh] grid-auto-rows[33vh] gap-1 md:(gap-2) lg:(grid-cols-4)">
          {data.collections.edges.map(({ node }, index) => (
            <CollectionCard
              authors={node.frontmatter.authors}
              title={node.frontmatter.title}
              key={node.fields.slug}
              path={node.fields.slug}
              summary={node.frontmatter.summary}
              places={node.frontmatter.places}
              sourceDirectory={node.fields.sourceDirectory}
              featured={false}
            />
          ))}
          <Link
            to='/collections'
            className="group"
            css={[
              tw`-webkit-backface-visibility[hidden] overflow-hidden backface-visibility[hidden] transform-gpu justify-center bg-thirdgray-200 inline-flex items-center relative`,
            ]}>
            <div tw="relative z-20 text-white md:(text-lg)">More Collections →</div>
            <div tw="absolute z-10 inset-0 backgroundColor[#3C3E41] opacity-40 transition-opacity duration-500 group-hover:opacity-20 backdropFilter[blur(4px)]"/>
            <div tw="absolute inset-0 transform scale-100 transition[transform 6s ease] group-hover:scale-125">
              <Img fluid={data.summaryCallToAction.childImageSharp.fluid} tw="absolute! z-0 inset-0"/>
            </div>
          </Link>
        </div>
      </div>
      <DirectoryWidget/>
      <H2 tw="text-center">Recently updated places</H2>
      <PlaceGrid>
        {data.recentPlaces.edges.map(({ node: place }) => {
          return (
            place.frontmatter && (
              <PlaceItem
                key={place.id}
                place={place}/>
            )
          )
        })}
      </PlaceGrid>
      <Summary/>
      <Footer/>
    </Layout>
  )
}

export const query = graphql`
  {
    collections: allMdx(filter: {fields: {sourceName: {eq: "collections"}, sourceDirectory: {ne: "where-to-order-classic-cocktails"}}}, sort: {fields: frontmatter___date, order: DESC}, limit: 3) {
      edges {
        node {
          fields {
            slug
            sourceDirectory
            sourceName
          }
          frontmatter {
            title
            summary
            places
          }
        }
      }
    }
    recentPlaces: allMdx(filter: {fields: {sourceName: {eq: "places"}}, frontmatter: {draft: {eq: false}, closed: {ne: true}, places: {}}}, sort: {fields: fields___creationDate, order: DESC}, limit: 10) {
      edges {
        node {
          id
          fields {
            slug
            sourceDirectory
          }
          frontmatter {
            name
            speciality
            tags
            price
            occasions
            article {
              title
              url
            }
            locations {
              street
              suburb
              region
              postcode
              country
            }
          }
        }
      }
    }
    featuredCollection: mdx(fields: { sourceDirectory: { eq: "where-to-order-classic-cocktails" } }) {
      fields {
        slug
      }
      frontmatter {
        title
        summary
        places
      }
      body
    }
    featuredImage: file(relativeDirectory: {eq: "where-to-order-classic-cocktails"}, name: {eq: "hero"}) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    summaryCallToAction: file(relativeDirectory: {eq: "affordable-date-nights-in-perth"}, name: {eq: "hero"}, childImageSharp: {fluid: {}}) {
        name
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    site {
      siteMetadata {
        holidayFilters
      }
    }
  }
`

export default HomePage
