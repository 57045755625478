import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import tw from 'twin.macro'

const Toast = ({ children, ...props}) => {
  const [ didMount, setDidMount ] = useState(false)

  useEffect(() => {
    setDidMount(true)
  }, [])

  const styles = {
    banner: () => [
      tw`absolute top-0 bottom-0 bg-blue-600 w-1/2 z-0 transition delay-300 duration-300 transform scale-x-0`
    ]
  }
  return (
    <Link tw="block text-center font-bold text-sm relative z-10 py-2 text-white" {...props}>
      <div
        css={[
          tw`relative z-30 transition delay-500 transform translate-y-1/4 opacity-0`,
          (didMount) && tw`translate-y-0 opacity-100`
        ]}>
        {children}
      </div>
      <div
        css={[
          styles.banner(),
          tw`left-0 origin-left`,
          (didMount) && tw`transform scale-x-100`
        ]}/>
      <div
        css={[
          styles.banner(),
          tw`right-0 origin-right`,
          (didMount) && tw`transform scale-x-100`
        ]}/>
    </Link>
  )
}

export default Toast
